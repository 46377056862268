import React, { useState, createContext, useContext } from 'react';

const FanClubContext = createContext();

export const useFanClubContext = () => useContext(FanClubContext);

// eslint-disable-next-line react/prop-types
export default ({ children }) => {
  const [locale, setLocale] = useState('');
  const [shouldStick, setShouldStick] = useState(true);
  const [shouldUnstick, setShouldUnstick] = useState(false);
  const [countryInfo, setCountryInfo] = useState({
    allowed: '',
    termsUrl: '',
    loading: true,
  });

  const contextData = {
    locale,
    setLocale,
    shouldStick,
    setShouldStick,
    shouldUnstick,
    setShouldUnstick,
    countryInfo,
    setCountryInfo,
  };
  return <FanClubContext.Provider value={contextData}>{children}</FanClubContext.Provider>;
};
