import { PURCHASE_TERMS_BASE } from '../constants/routes';
import { countryLocales as SUPPORTED_COUNTRY_LOCALES } from '../../i18n';

const localeMap = [
  {
    localeCode: 'US',
    countries: ['US'],
  },
  {
    localeCode: 'UK',
    countries: ['GB', 'JE', 'GG', 'IM'],
  },
];

export const EU_COUNTRY_STRINGS = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];

export const appAvailableLocales = [
  'UK', // United Kingdom
  'US', // United States
  'GB', // Great Britain
  'IE', // Ireland
  'US', // United States
  'NZ', // New Zealand
  'AU', // Australia
  'CA', // Canada
  'FR', // France
  'DE', // Germany
  'ES', // Spain
  'IT', // Italy
  'SE', // Sweden
  'NL', // Netherlands
  'BE', // Belgium
  'PT', // Portugal
  'DK', // Denmark
  'NO', // Norway
  'FI', // Finland
  'AT', // Austria
  'CH', // Switzerland
  'ZA', // South Africa
  'BR', // Brazil
  'MX', // Mexico
  'IN', // India
  'ID', // Indonesia
  'PH', // Philippines
  'SG', // Singapore
  'MY', // Malaysia
];

export const getLocaleFromCountryCode = (countryCode) => {
  const locale = localeMap.find((mapping) => mapping.countries.includes(countryCode));
  return locale ? locale.localeCode : 'OTHER';
};

export const getCurrencySymbol = (countryCurrency) => {
  let currencySymbol;
  switch (countryCurrency) {
    case 'USD':
      currencySymbol = '$';
      break;
    case 'GBP':
      currencySymbol = '£';
      break;
    case 'EUR':
    default:
      currencySymbol = '€';
      break;
  }
  return currencySymbol;
};

export const getTermsUrlByCountry = (countryCode) => {
  let termsUrl;
  switch (countryCode) {
    case 'UK':
    case 'GB':
    case 'JE':
    case 'GG':
    case 'IM':
      termsUrl = `${PURCHASE_TERMS_BASE}-uk`;
      break;
    default:
      termsUrl = `${PURCHASE_TERMS_BASE}-${countryCode.toLowerCase()}`;
      break;
  }
  return termsUrl;
};

// Used to get a 5 character xx-YY locale from a combination of language and country code
// This is used mainly to convert the current selected language to a locale
// the backend expects in some api requests.
export const getSupportedCountryLocale = (language, countryCode) => {
  if (language === 'en') {
    const locale = getLocaleFromCountryCode(countryCode);
    return locale === 'UK' ? 'en-GB' : 'en-US';
  }

  return SUPPORTED_COUNTRY_LOCALES?.[language]?.[0] || 'en-US';
};
