
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import PropTypes from 'prop-types';
import logger from '@utils/logger';
import Error404 from '../../src/components/Error404';
import HarryPotterFanClub from '../../src/pages/HarryPotterFanClub';
import { fetchContent, withNavigation } from '../../src/utils/contentfulHelpers';
import FanClubContextProvider from '../../src/contexts/fan-club-context';
const propTypes = {
    fanClubData: PropTypes.shape({}).isRequired,
    locale: PropTypes.string,
    errors: PropTypes.string
};
const HarryPotterFanClubPage = ({ errors = null, fanClubData, locale = '' }) => {
    if (!fanClubData) {
        return <Error404 statusCode={errors ? errors.statusCode : 404}/>;
    }
    return (<>
      <FanClubContextProvider>
        <HarryPotterFanClub fanClubData={fanClubData} locale={locale}/>
      </FanClubContextProvider>
    </>);
};
HarryPotterFanClubPage.getInitialProps = async ({ res, req, asPath }) => {
    const externalId = 'harry-potter-fan-club';
    const variables = {
        contentTypes: ['page'],
        externalId
    };
    let locale = '';
    if (req) {
        locale = req.header('Cloudfront-Viewer-Country') || '';
    }
    const [content, errors] = await fetchContent(variables);
    const location = `Page: ${asPath}`;
    logger(location, content, errors);
    if (errors) {
        if (res)
            res.statusCode = errors.statusCode;
        return { errors, fanClubData: null, locale: null };
    }
    const props = await withNavigation({
        fanClubData: content?.[0]?.body,
        locale
    });
    return props;
};
HarryPotterFanClubPage.propTypes = propTypes;
const __Next_Translate__Page__191c29da393__ = HarryPotterFanClubPage;

    export default __appWithI18n(__Next_Translate__Page__191c29da393__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  