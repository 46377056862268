
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { setCountryInfo, setLocale } from '../../globalStates/HPFCState';
import useCountry from '../../hooks/useCountry';
import Layout from '../../components/MyLayout';
import { getLocaleFromCountryCode, getTermsUrlByCountry } from '../../utils/locales';
import { COUNTRY_PRODUCTS_QUERY } from '../../utils/graphQueries';
import GenericPage from '../../components/GenericPage';
import Image from '../../components/Image';
import s from './HarryPotterFanClub.module.scss';
import bg from './imgs/background.jpg';
import bg2x from './imgs/background@2x.jpg';
import bg3x from './imgs/background@3x.jpg';
const propTypes = {
    fanClubData: PropTypes.shape({
        articles: PropTypes.array,
        referencedContent: PropTypes.object,
        searchTitle: PropTypes.string,
        searchDescription: PropTypes.string,
        associatedTag: PropTypes.array,
        shortDescription: PropTypes.string,
        displayTitle: PropTypes.string,
        mainImage: PropTypes.object,
        mainVideo: PropTypes.string,
        entryTitle: PropTypes.string
    }),
    locale: PropTypes.string
};
const bgSet = [
    {
        src: `${bg}, ${bg2x} 2x, ${bg3x} 3x`,
        type: 'image/jpeg'
    },
    {
        src: bg
    },
];
const HarryPotterFanClub = ({ fanClubData = {}, locale = '' }) => {
    const fanClubDataExtended = useMemo(() => ({
        searchTitle: 'Harry Potter Fan Club | Wizarding World',
        searchDescription: 'Harry Potter Fan Club',
        theme: 'fan-club',
        ...fanClubData
    }), [fanClubData]);
    const { country } = useCountry('');
    const countryCode = country || locale;
    const userLocale = getLocaleFromCountryCode(countryCode);
    setLocale(userLocale);
    const { data: countryData } = useQuery(COUNTRY_PRODUCTS_QUERY, {
        ssr: false
    });
    const countryInfo = useMemo(() => {
        /* istanbul ignore next */
        if (countryData) {
            return {
                allowed: countryData.country?.allowed,
                termsUrl: getTermsUrlByCountry(countryData.country?.code),
                loading: false
            };
        }
        return {
            allowed: '',
            termsUrl: '',
            loading: true
        };
    }, [countryData]);
    setCountryInfo(countryInfo);
    return (<Layout theme="fan-club">
      <div className={s.root}>
        <Image imageSet={bgSet} cover/>
        <GenericPage pageData={fanClubDataExtended}/>
      </div>
    </Layout>);
};
HarryPotterFanClub.propTypes = propTypes;
export default HarryPotterFanClub;

    async function __Next_Translate__getStaticProps__191c29daf79__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//HarryPotterFanClub/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c29daf79__ as getStaticProps }
  